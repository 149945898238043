<template>
	<router-view></router-view>
</template>

<script>
// import { mapActions } from 'vuex';
import store from "@/store/store";

export default {
	async beforeRouteEnter(to, from, next) {
		// debugger;
		await Promise.all([
			// store.dispatch('auth/info'),
			store.dispatch("branches/load"),
		]);
		// debugger;
		next();
	},
};
</script>
